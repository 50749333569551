<template>
  <metainfo></metainfo>
  <teleport to="head">
    <component :is="'script'" type="application/ld+json">
      {
        "@context": "http://schema.org",
        "@type": "LocalBusiness",
        "name": "Rexroth Development LLC",
        "image": "https://rexrothdev.com/img/logo2.22ea8c9a.png",
        "telephone": "+1-317-610-0497",
        "email": "andrew@rexrothdev.com",
        "url": "https://rexrothdev.com",
        "address": {
          "@type":"PostalAddress","addressLocality":"Indianapolis","addressRegion":"IN","addressCountry":"US","postalCode":"46220","streetAddress":"6101 North Keystone Suite 1200c #1117"
        }
      }
    </component>
  </teleport>
  <!-- <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/animate.css@latest/animate.min.css" /> -->
  <PrivacyPolicy class="PrivacyPolicy" v-click-outside="onClickOutside" v-if="showpp" />
  <!-- <div v-click-outside="onClickOutside" :style="{'z-index': '101' }">TESTING TESTING 123</div> -->
  <router-view></router-view>

  <main-view>
  </main-view>
</template>

<script>
import { useMeta } from 'vue-meta'
import PrivacyPolicy from './components/layouts/PrivacyPolicy.vue'
export default {
  setup () {
    useMeta({
      title: 'Rexroth Development LLC',
      htmlAttrs: { lang: 'en' },
      description: 'Rexroth Development LLC is a software development company that provides Custom full stack software development solutions. We specialize in custom development for: websites, mobile applications, cloud-based services and API integrations. This is a remote company that works with small businesses or individuals to design and develop their desired unique software applications.',
      keywords: 'remote software development services, remote programming services, freelance software developers, remote web development, remote full stack development',
      author: 'Andrew Rexroth',
      viewport: 'width=device-width, initial-scale=1.0',
      robots: 'index, follow',
      link: [
        { rel: 'canonical', href: 'https://rexrothdev.com/' },
        { rel: 'icon', type: 'image/png', href: '/rexrothDevIcon.png' },
        { rel: 'apple-touch-icon', href: '/rexrothDevIcon.png' }
      ],
      titleTemplate: '%s | Rexroth Development LLC'
    })
  },
  name: 'App',
  components: { PrivacyPolicy },
  created () {
    // this.createMessage()
  },
  methods: {
    // async createMessage() {
    //   const contactsCol = collection(db, 'contacts')
    //   const dataObj = {
    //     firstname: 'andrew',
    //     lastname: 'rexroth',
    //     email: 'andrewjrexroth@gmail.com',
    //     message: 'testMessage'
    //   }
    //   const docRef = await addDoc(contactsCol,dataObj);
    //   console.log('Document was created with ID: ', docRef.id);
    // }
    onClickOutside () {
      console.log('Outside')
      this.$store.dispatch('setShowPP', { showpp: false})
    }
  },
  computed: {
    showpp() {
      return this.$store.getters.getShowPP
    }
  }
}
</script>
<style>

body{
  font-family: Helvetica, sans-serif;
}
</style>
