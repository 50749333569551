<template>
  <!-- <div class="navLine"></div> -->
  <div class="background">
    <div class="headerDivs">
      <div class="topLeft">
      </div>
      <div class="topMiddle">
        <!-- <a @click="goto('home')" class="navBtn" :class="{underlineText: activePage==='home' }" :style="[!isMobile? {'float':'left' }: {}] ">Home</a> -->
        <!-- <a @click="goto('projects')" class="navBtn" :class="{underlineText: activePage==='projects' }" :style="[!isMobile?{'float':'right'}:{}]">Projects</a>
        <a @click="goto('about')" class="navBtn" :class="{underlineText: activePage==='about' }" :style="[!isMobile?{'text-align': 'center', 'margin':'auto'}:{}]">About</a>
        <a v-if="isMobile" @click="goto('contact')" class="navBtn" :class="{underlineText: activePage==='contact' }" >Contact Us</a> -->
        <router-link :to="{name: 'mainview', query: {id: 'home' }}" class="navBtn" :class="activePage==='home' ? 'underlineText' : 'removeUnderline'" >Home</router-link>        <!-- :style="[!isMobile? {'float':'left'}:{}]" -->
        <router-link :to="{name: 'mainview', query: {id: 'projects' }}" class="navBtn" :class="activePage==='projects' ? 'underlineText' : 'removeUnderline'" >Projects</router-link><!-- :style="[!isMobile? {'float':'right'}:{}]" -->
        <router-link :to="{name: 'mainview', query: {id: 'about' }}" class="navBtn" :class="activePage==='about' ? 'underlineText' : 'removeUnderline'" >About</router-link> <!-- :style="[!isMobile? {'text-align': 'center', 'margin':'auto'}:{}]" -->
        <div :class="[ 'navBtn', 'pp']" @click="setShowPP">Privacy Policy</div>

        <!-- <router-link v-if="isMobile" :to="{name: 'mainview', query: {id: 'contact' }}" class="navBtn" :class="{underlineText: activePage==='contact'}" >Contact Us</router-link> -->
      </div>
      <div v-if="!isMobile" class="topRight"><router-link :to="{name: 'mainview', query: {id: 'contact'}}" :class="[activePage==='contact' ? 'underlineText' : 'removeUnderline', 'contactUs']">Contact Us</router-link></div>
      <!-- <div v-if="!isMobile" class="topRight"><div class="contactUs" :class="{underlineText: activePage==='contact' }" @click="goto('contact')">Contact Us</div></div>     -->
    </div>
    <div class="navLine"></div>
  </div>
</template>

<script>
export default {
  // props: ['goto', 'activePage'],
  props: ['activePage'],
  emits: ['openPP'],
  data () {
    return {
      isMobile: false
    }
  },
  methods: {
    windowSize () {
      // console.log(window.innerWidth);
      if (window.innerWidth <= 900) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
      console.log('isMobile: ' + this.isMobile)
    },
    setShowPP () {
      console.log('setShowPP: true')
      this.$store.dispatch('setShowPP', { showpp: true })
    }
  },
  mounted () {
    window.addEventListener('resize', this.windowSize)
    this.windowSize()
  }
}
</script>

<style scoped>
a:-webkit-any-link {
  text-decoration: none;
}
.underlineText {
  border-bottom: 1px solid white;
}
.removeUnderline {
  border-bottom: none;
}
.navLine {
  background-color:white;
  height:5px;
}
.headerDivs {
  display:flex;
  justify-content: center;
  align-items:center;;
  background-color:rgb(100, 100, 100);
  height:6vh;
  /* background-color:gold; */
}
.topLeft {
  /* background-color:green; */
  font-size: 2rem;
  font-family: Helvetica, sans-serif;
  color:black;
  width:30%;
  /* margin-top:.05em; */
  margin-left: 3rem;
}
.topMiddle {
  /* background-color:orange; */

  font-size:1.1em;
  width: 40%;
  margin:auto;
  text-align:center;
  display: flex;
  justify-content: space-between;
}
.topMiddle a {
  /* background-color:red;   */
  font-family: Helvetica, sans-serif;
  color:rgb(255, 255, 255);
  margin-bottom:0px;
  padding-bottom:0px;
}
.pp {
  font-family: Helvetica, sans-serif;
  color:rgb(255, 255, 255);
  margin-bottom:0px;
  padding-bottom:0px;
}
.navBtn:hover{
  color:rgb(207, 207, 207);
  cursor: pointer;
  border-bottom:1px solid rgb(207, 207, 207);
}
.topRight {
  color:white;
  font-size: 1.5rem;
  font-family: Helvetica, sans-serif;
  transition-duration: 0.1s;
  margin-right: 3rem;
  width:30%;
  text-align:right;
}
.contactUs {
  display:inline-block;
  color:white;
}
.contactUs:hover {
  cursor:pointer;
  display:inline-block;
  color:rgb(207, 207, 207);
  border-bottom:1px solid rgb(207, 207, 207);
}
.home {
  border-bottom:1px solid white;
}

@media only screen and (max-width: 900px) {
/* .background {
  width:100vw;
  height:100vh;
} */
/* .headerDivs {
  width:100%;
} */
.topMiddle{
  width:100%;
  display:flex;
  justify-content: space-evenly;
}
.topLeft, .topRight {
  margin:0;
  width:0;
}

}
</style>
