// components/BlogList.vue
<template>
  <div>
    <h1>Blog Posts</h1>
    <ul>
      <li v-for="post in posts" :key="post.sys.id">
        <router-link :to="'/blog/' + post.fields.slug">{{ post.fields.title }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import contentful from '../contentful';
export default {
  data() {
    return {
      posts: [],
    };
  },
  created() {
    contentful.getEntries({ content_type: 'blogPost'}).then((response) => {
      this.posts = response.items;
    }).catch(console.error);
  }
};
</script>