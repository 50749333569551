import { createApp } from 'vue'
import { createMetaManager } from 'vue-meta'
import vClickOutside from 'click-outside-vue3'
// import router from './router.js';
import App from './App.vue'
// import BaseNav from './components/layouts/BaseNav.vue';
// import TheHeader from "./components/layouts/TheHeader.vue";
// import BaseButton from './components/layouts/BaseButton.vue';
// import MainView from "./pages/MainView.vue";
// import HomeView from './components/layouts/HomeView.vue';
// import AboutView from './components/layouts/AboutView.vue';
// import ContactView from './components/layouts/ContactView.vue';
// import ProjectsView from './components/layouts/ProjectsView.vue';
// import ProjectView from './components/layouts/ProjectView.vue';
import router from './router.js';
import store from './store/index.js';
import VueGtag from "vue-gtag";
// import { VueFire, VueFireAuth} from 'vuefire';
// import {firebaseApp} from './firebaseConfig';



const app = createApp(App)
app.use(createMetaManager())
app.use(router)
app.use(store)
app.use(vClickOutside)
app.use(VueGtag, {
  config: { id: "AW-16527861183" }
});
// app.use(VueFire, {
//   modules: [
//     VueFireAuth(),
//   ],
// })
// app.component('base-nav', BaseNav);
// app.component('the-header', TheHeader);
// app.component('base-button',BaseButton);
// app.component('main-view',MainView);
// app.component('home-view',HomeView);
// app.component('about-view',AboutView);
// app.component('projects-view',ProjectsView);
// app.component('project-view', ProjectView);
// app.component('contact-view',ContactView);

app.mount('#app')
