<template>
    <div class="topSection">
      <the-header :goto="goto" activePage="home"></the-header>
      <div class="welcomePrompt">
        <div class="logo">
          <!-- <h1>Rexroth Development</h1> -->
          <!-- <h2>Generating unique front-end and back-end solutions for all types of projects</h2> -->
          <!-- <h2>High Quality Software Solutions</h2> -->
          <img src="../../assets/logo2.png" class="logoImg" alt="Logo"/>
        </div>
      </div>
    </div>
    <div class="middleSection">
      <div class="secondPrompt">
        <!-- We take on all types of projects: websites, mobile applications, cloud-based services, API integrations, full-stack development. If you are a an individual looking to market yourself or develop an idea,
        or a business needing a more complex software solution, you are in the right place.         -->
        <!-- <div class="spText">Custom full stack software development: websites, mobile applications, cloud-based services, API integrations. Let's discuss your unique software need today (<a class="hereLink" @click="goto('contact')">Contact Us</a>).</div> -->
        <div class="spText">Custom full stack software development: websites, mobile applications, cloud-based services, API integrations. Let's discuss your unique software need today (<router-link :to="{name: 'mainview', query: {id: 'contact'}}" class="hereLink">Contact Us</router-link>).</div>
      </div>
    </div>
</template>

<script>
export default {
  props: ['goto'],
  data () {
    return {
      isMobile: false
    }
  },
  methods: {
    windowSize () {
      // console.log(window.innerWidth);
      if (window.innerWidth <= 900) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
      console.log('isMobile: ' + this.isMobile)
    }
  },
  mounted () {
    window.addEventListener('resize', this.windowSize)
    this.windowSize()
  }
}
</script>

<style scoped>
.topSection {
  background-color:rgb(86, 105, 133);
  width: 100%;
  height: 65vh;
  color:black;
}
.middleSection {
  background-color:rgb(167, 172, 185);
  width: 100%;
  height: 35vh;
}
.welcomePrompt{
  height:calc(59vh - 13px);
  display:flex;
  text-align:center;
  align-items: center;
  justify-content: center;
  font-size:1.5em;
}
.logo {
  background-color:white;
  padding:5px;
  border-style: inset;
  border-width: 5px;
  height:70%;
}
.logoImg {
  height:100%;
}
.welcomePrompt h2 {
  font-style:italic;
  font-size:1.2em;
}
.secondPrompt {
  width: 50vw;
  height:100%;
  text-align:center;
  margin:auto;
  display:flex;
  align-items: center;
  font-size:1.5em;
}
.spTitle {
  font-size:1.3em;
}
.spText {

}
.hereLink {
  color:rgb(52, 52, 172);
  text-decoration: underline;
}
.hereLink:hover {
  color:rgb(71, 100, 197);
  cursor:pointer;
}

@media only screen and (max-width: 900px) {

.logo{
  width:80vw;
  height:auto;
}

.logoImg {
  width:80vw;
}
.welcomePrompt{
  height:calc(59vh - 6vh);
}
.spText {
  font-size:.8em;
}

.topSection{
  height:60vh;
}
.middleSection{
  height:40vh;
}
.secondPrompt {
  width:82vw;
}
}
</style>
