<template>
  <div v-if="post && post.fields">
    <h1>{{post.fields.title }}</h1>
    <img :src="`https:${post.fields.featuredImage.fields.file.url}`" alt="Featured Image">
    <div v-html="post.fields.body.content[0].content[0].value"></div>
  </div>
</template>

<script>
import {client, previewClient} from '../contentful';
import { ContentfulLivePreview } from '@contentful/live-preview';
// import { useContentfulLiveUpdates } from '@contentful/live-preview';

export default {
  data() {
    return {
      post: {},
    };
  },
  created() {
    const isPreview = this.$route.query.preview==='true';
    const slug = this.$route.params.slug;
    const contentfulCient = isPreview ? previewClient : client;
    contentfulCient
      .getEntries({ content_type: 'blogPost', 'fields.slug': slug })
      .then((response) => {
        this.post = response.items[0];
      })
      .catch(console.error);
  },
  mounted() {
    ContentfulLivePreview.init({
      locale: 'en-US',
    })
  }
};
</script>