import { createRouter, createWebHistory } from 'vue-router'
import store from './store/index.js';

import MainView from './pages/MainView.vue';
import BlogList from './pages/BlogList.vue';
import BlogPost from './pages/BlogPost.vue';
import Login from './auth/Login.vue';
import SignUp from './auth/SignUp.vue';

const router = createRouter({
  history: createWebHistory(),
  // routes: [
  //     { path: '/', redirect: '/home'},
  //     { path: '/home', name: 'mainview', component: MainView },
  // ],
  routes: [
    {
      path: '/',
      name: 'mainview',
      component: MainView,
      alias: ['/home', '/about', '/projects', '/contact']
    },
    {
      path: '/blog',
      component: BlogList
    },
    {
      path: '/blog/:slug',
      component: BlogPost
    },
    { 
      path: '/blog/:slug/preview',
      component: BlogPost,
      props: (route) => ({preview: route.query.preview==='true'})
    },
    {
      path: '/login',
      component: Login
    },
    {
      path: '/signup',
      component: SignUp
    }
  ],
  scrollBehavior (to) {
    // if(savedPosition) {
    //     console.log("Router Saved Position", savedPosition)
    //     return savedPosition;
    // }
    //  START HERE
    // 1. Check if done loading
    // 2. then do window.scrollTo
    // 3. randomly scrolling to different area of page?
    if(to.query && to.query.pp && to.query.pp === 'true'){      
      setTimeout(() => {
        store.dispatch('setShowPP', { showpp: true })
      }, 100);      
    }
    if (to.query && to.query.id) {      
      if (to.params.update === null || to.params.update != 'false') {
        setTimeout(() => {
          console.log('Router Query', to.query.id)
          document.title = to.query.id.charAt(0).toUpperCase() + to.query.id.slice(1) + ' | Rexroth Development'
          if (to.query.update === 'false') {
            return
          }
          if (to.query.id === 'home') {
            window.scrollTo({ top: 0, behavior: 'smooth' })
          } else if (to.query.id === 'contact') {
            // var b = document.getElementById('contact').getBoundingClientRect().bottom + 8;
            const b = document.getElementById('contact').offsetTop + 8
            window.scrollTo({ top: b, behavior: 'smooth' })
          } else {
            document.getElementById(to.query.id).scrollIntoView({ behavior: 'smooth', block: 'start' })
          }
        }, 100)
      }
    }
  }
})

export default router
