<template>
  <transition>
    <div class="theHeader" v-if="showHeader">
      <div class="whiteTop"></div>
      <the-header :activePage="activePage"></the-header>
    </div>
  </transition>
  <div class="mainPage" ref="home" id="home">
    <div class="whiteTop" ></div>
    <the-header :activePage="activePage"></the-header>
    <div
      @click="goto('home')"
      class="returnButton"
      :class="returnButtonClass"
      v-if="showReturnToTopButton"
    >
      back to top
    </div>
    <div>
      <home-view :goto="goto"></home-view>
    </div>
    <div ref="about" id="about">
      <about-view></about-view>
    </div>
    <div ref="projects" id="projects">
      <projects-view :goto="goto"></projects-view>
    </div>
    <!-- ********** Inn At Windmere ********** -->
    <div ref="project1">
      <project-view
        projectName="Inn At Windmere"
        backgroundColor="rgb(100, 100, 100)"
      >
        <div class="pView">
          <div class="pNav">
            <div
              class="pBtn"
              @click="navActive[0] = 'desc'"
              :class="[
                { navSelected: navActive[0] === 'desc' },
                { navUnderlined: navActive[0] != 'desc' },
              ]"
            >
              Overview
            </div>
            <div class="pNavSpace"></div>
            <div
              class="pBtn"
              @click="navActive[0] = 'details'"
              :class="[
                { navSelected: navActive[0] === 'details' },
                { navUnderlined: navActive[0] != 'details' },
              ]"
            >
              Details
            </div>
            <div class="pNavSpace"></div>
            <div
              class="pBtn"
              @click="navActive[0] = 'view'"
              :class="[
                { navSelected: navActive[0] === 'view' },
                { navUnderlined: navActive[0] != 'view' },
              ]"
            >
              Example
            </div>
          </div>
          <div class="pBodyH" v-if="navActive[0] === 'desc'">
            <div class="pBody1">
              <div class="pLeft">
                <div class="pTitle">Description</div>
                <div class="pContent">
                  To develop a business website for a Bed and Breakfast.<br>
                  You can find the website here: <a href="https://innatwindmere.com" target="_blank">Inn At Windmere</a>
                </div>
                <br /><br /><br />
                <div class="pTitle">Requirements</div>
                <div class="pContent">
                  <ul>
                    <li>Display info about the company</li>
                    <li>Display video walkthrough on the home page</li>
                    <li>Display info about each room</li>
                    <li>Display retreat info, amenities, events, attractions, and Policies</li>
                    <li>Integrate a reservation site</li>
                    <li>
                      Provide a form where users can submit contact info and questions
                    </li>
                    <li>Low cost design</li>
                  </ul>
                </div>

                <!-- <p>
                  This project was to take an existing squarespace website and come up with a method of integrating more complex functionality within it. <br><br>
                  The goal was to update the website to display current products available at specific locations, and to allow the user to search for the closest location of a specific product.
                  If possible, the site was to be unchanged otherwise and it needed to be done in an effecient mannor.
                </p> -->
              </div>
              <div class="pRight">
                <div class="pTitle">Outcome</div>
                <div class="pListTitle">Low cost design</div>
                <ul>
                  <li>Hosted site using firebase: no yearly cost</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="pBodyV" v-else-if="navActive[0] === 'details'">
            <div class="pBody2">
              <div class="pBlock1">
                <div class="pTitle">Solution</div>
                <div class="pContent">
                  <!-- Details > Solution -->
                  To develop a website from scratch using vue.js to meet clients
                  exact design requests<br /><br />
                  <div class="pListTitle">Firebase</div>
                  <ul>
                    <li>Website is hosted in firebase</li>
                    <!-- <li>Message data is stored in firebase firestore database</li> -->
                  </ul>
                  <br />
                  <div class="pListTitle">Message Functionality</div>
                  <ul>
                    <li>
                      Messages and contact info are emailed to the company using
                      emailjs
                    </li>
                    <!-- <li>
                      Messages and contact info are stored in firestore database
                    </li>
                    <li>Provided desktop application to company to view data</li> -->
                  </ul>
                  <div class="pListTitle">Reservations</div>
                  <ul>
                    <li>The 3rd party reservation website is displayed within an iframe</li>
                  </ul>
                </div>
              </div>
              <div class="pBlock2">
                <div class="pTitle">Tech Stack</div>
                <div class="pContent">
                  <ul>
                    <li>Firebase (Firebase hosting)</li>
                    <li>Vue.js</li>
                    <li>Javascript, HTML, CSS</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="pBodyI" v-else>
            <iframe
              src="https://innatwindmere.com"
              width="100%"
              height="100%"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </project-view>
    </div>
    <!-- ********** Dabstract Drops ********** -->
    <div style="height: 100%" ref="project2">
      <project-view
        projectName="Dabstract Drops"
        backgroundColor="rgb(100, 100, 100)"
      >
        <div class="pView">
          <div class="pNav">
            <div
              class="pBtn"
              @click="navActive[1] = 'desc'"
              :class="[
                { navSelected: navActive[1] === 'desc' },
                { navUnderlined: navActive[1] != 'desc' },
              ]"
            >
              Overview
            </div>
            <div class="pNavSpace"></div>
            <div
              class="pBtn"
              @click="navActive[1] = 'details'"
              :class="[
                { navSelected: navActive[1] === 'details' },
                { navUnderlined: navActive[1] != 'details' },
              ]"
            >
              Details
            </div>
            <div class="pNavSpace"></div>
            <div
              class="pBtn"
              @click="navActive[1] = 'view'"
              :class="[
                { navSelected: navActive[1] === 'view' },
                { navUnderlined: navActive[1] != 'view' },
              ]"
            >
              Example
            </div>
          </div>
          <div class="pBodyH" v-if="navActive[1] === 'desc'">
            <div class="pBody1">
              <div class="pLeft">
                <div class="pTitle">Description</div>
                <div class="pContent">
                  To take an existing website built in squarespace and add more
                  complex functionality beyond the capabilities of a squarespace
                  template. To load new product data dynamically each week and
                  display the types of products that are delivered to each store
                  and on what day. Also, to allow the user to filter for specific
                  products and learn the closet store carring this product.
                </div>
                <br /><br /><br />
                <div class="pTitle">Requirements</div>
                <div class="pContent">
                  <ul>
                    <li>
                      Allow user to filter for products by: City, Store, Strain,
                      Product type, or Zip code
                    </li>
                    <li>
                      Update the resulting filtered data after each selection made
                      by user
                    </li>
                    <li>
                      If user shares their location or enters a zip code, display
                      results at the 25 nearest stores that match the filter
                      criteria
                    </li>
                    <li>Efficient and responsive design</li>
                    <li>Low cost design</li>
                    <li>
                      Display store info and all products that are available at
                      each store
                    </li>
                  </ul>
                </div>

                <!-- <p>
                  This project was to take an existing squarespace website and come up with a method of integrating more complex functionality within it. <br><br>
                  The goal was to update the website to display current products available at specific locations, and to allow the user to search for the closest location of a specific product.
                  If possible, the site was to be unchanged otherwise and it needed to be done in an effecient mannor.
                </p> -->
              </div>
              <div class="pRight">
                <div class="pTitle">Outcome</div>
                <div class="pListTitle">Costs of new features in site</div>
                <ul>
                  <li>
                    AWS: given our low number of users, we don't exceed what is
                    offered in Amazon's free tier, thus there is no cost
                  </li>
                  <li>
                    Google API: given our low number of users, we don't exceed
                    what is offered for free by Google, thus there is no cost
                  </li>
                  <li>
                    All features were able to be implemented without any
                    additional charges to the company for their website
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="pBodyV" v-else-if="navActive[1] === 'details'">
            <div class="pBody2">
              <div class="pBlock1">
                <div class="pTitle">Solution</div>
                <div class="pContent">
                  <!-- Details > Solution -->
                  To use custom javascript code blocks injected into a squarespace
                  page, Amazon Web Services for backend functionality, and Google
                  API for location lookup to create an effecent solution<br /><br />
                  <div class="pListTitle">Dynamically Loading Current Data</div>
                  <ul>
                    <li>
                      Cloudwatch event fires to pull data in from a google sheet
                      on a weekly schedule
                    </li>
                    <li>
                      Data is parsed and stored in desired format in a DynamoDB
                      table
                    </li>
                    <li>
                      Requests to Google Geocoding API convert all store addresses
                      found in the dataset to lat/long coordinates to be stored in
                      the DynamoDB aswell
                    </li>
                  </ul>
                  <br />
                  <div class="pListTitle">Loading Filtered Data in Website</div>
                  <ul>
                    <li>
                      When the user lands on the site or makes a filtered
                      selection, an http request is sent via AWS Gateway API to a
                      Lambda function responsible for pulling the desired data
                      from the DynamoDB table and returning it as html to be
                      rendered in the squarespace page
                    </li>
                  </ul>
                  <br />
                  <div class="pListTitle">Handling Location Data</div>
                  <ul>
                    <li>
                      The current users location (or entered zip code) is used
                      along with the other filter criteria to mathmatically
                      approximate the closest 25 stores
                    </li>
                    <li>
                      This list of 25 stores is then sent in a single request to
                      the Google Distance Matrix to determine the exact driving
                      distance from the current users location
                    </li>
                  </ul>
                </div>
              </div>
              <div class="pBlock2">
                <div class="pTitle">Tech Stack</div>
                <div class="pContent">
                  <ul>
                    <li>AWS (Lambda, DynamoDB, GatewayAPI, IAM, CloudWatch)</li>
                    <li>Squarespace (javascript code injection)</li>
                    <li>Javascript, HTML, CSS</li>
                    <li>Python (AWS Lambdas)</li>
                    <li>Google Geocoding API</li>
                    <li>Google Distance Matrix API</li>
                    <li>Google Sheets</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="pBodyI" v-else>
            <!-- <iframe src="https://www.dabstract.com/find-dabstract-products-washington-state" width="100%" height="100%"  frameborder="0"></iframe> -->
            <div class="video1">
              <!-- <video-player vjs-fluid :options="videoOptions" /> -->
              <video autoplay muted loop ref="video">
                <source src="../assets/Dabstract.mp4"  type="video/mp4" />
              </video>

            </div>
          </div>
        </div>
      </project-view>
    </div>
    <!-- ********** Rexroth Building and Interiors ********** -->
    <div ref="project3">
      <project-view
        projectName="Rexroth Building and Interiors"
        backgroundColor="rgb(100, 100, 100)"
      >
        <div class="pView">
          <div class="pNav">
            <div
              class="pBtn"
              @click="navActive[2] = 'desc'"
              :class="[
                { navSelected: navActive[2] === 'desc' },
                { navUnderlined: navActive[2] != 'desc' },
              ]"
            >
              Overview
            </div>
            <div class="pNavSpace"></div>
            <div
              class="pBtn"
              @click="navActive[2] = 'details'"
              :class="[
                { navSelected: navActive[2] === 'details' },
                { navUnderlined: navActive[2] != 'details' },
              ]"
            >
              Details
            </div>
            <div class="pNavSpace"></div>
            <div
              class="pBtn"
              @click="navActive[2] = 'view'"
              :class="[
                { navSelected: navActive[2] === 'view' },
                { navUnderlined: navActive[2] != 'view' },
              ]"
            >
              Example
            </div>
          </div>
          <div class="pBodyH" v-if="navActive[2] === 'desc'">
            <div class="pBody1">
              <div class="pLeft">
                <div class="pTitle">Description</div>
                <div class="pContent">
                  To develop a business website for a small trim and construction
                  company.<br>
                  You can find the website here: <a href="https://rexroth-building.com" target="_blank">Rexroth Building and Interiors</a>
                </div>
                <br /><br /><br />
                <div class="pTitle">Requirements</div>
                <div class="pContent">
                  <ul>
                    <li>Display info about the company</li>
                    <li>
                      Allow user to message company, email message to company, add
                      message to database, create application for company to view
                      messages
                    </li>
                    <li>
                      Display photos of exterior and interior projects by category
                    </li>
                    <li>Low cost design</li>
                  </ul>
                </div>

                <!-- <p>
                  This project was to take an existing squarespace website and come up with a method of integrating more complex functionality within it. <br><br>
                  The goal was to update the website to display current products available at specific locations, and to allow the user to search for the closest location of a specific product.
                  If possible, the site was to be unchanged otherwise and it needed to be done in an effecient mannor.
                </p> -->
              </div>
              <div class="pRight">
                <div class="pTitle">Outcome</div>
                <div class="pListTitle">Low cost design</div>
                <ul>
                  <li>Hosted site using firebase: no yearly cost</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="pBodyV" v-else-if="navActive[2] === 'details'">
            <div class="pBody2">
              <div class="pBlock1">
                <div class="pTitle">Solution</div>
                <div class="pContent">
                  <!-- Details > Solution -->
                  To develop a website from scratch using vue.js to meet clients
                  exact design requests<br /><br />
                  <div class="pListTitle">Firebase</div>
                  <ul>
                    <li>Website is hosted in firebase</li>
                    <li>Message data is stored in firebase firestore database</li>
                  </ul>
                  <br />
                  <div class="pListTitle">Message Functionality</div>
                  <ul>
                    <li>
                      Messages and contact info are emailed to the company using
                      emailjs
                    </li>
                    <li>
                      Messages and contact info are stored in firestore database
                    </li>
                    <li>Provided desktop application to company to view data</li>
                  </ul>
                </div>
              </div>
              <div class="pBlock2">
                <div class="pTitle">Tech Stack</div>
                <div class="pContent">
                  <ul>
                    <li>Firebase (Firebase hosting, Firestore database)</li>
                    <li>Vue.js</li>
                    <li>Javascript, HTML, CSS</li>
                    <li>C# Desktop Application</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="pBodyI" v-else>
            <iframe
              src="https://rexroth-building.com"
              width="100%"
              height="100%"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </project-view>
    </div>
    <!-- ********** Full Potential ********** -->
    <div ref="project4">
      <project-view
        projectName="Full Potential Inc."
        backgroundColor="rgb(100, 100, 100)"
      >
        <div class="pView">
          <div class="pNav">
            <div
              class="pBtn"
              @click="navActive[3] = 'desc'"
              :class="[
                { navSelected: navActive[3] === 'desc' },
                { navUnderlined: navActive[3] != 'desc' },
              ]"
            >
              Overview
            </div>
            <div class="pNavSpace"></div>
            <div
              class="pBtn"
              @click="navActive[3] = 'details'"
              :class="[
                { navSelected: navActive[3] === 'details' },
                { navUnderlined: navActive[3] != 'details' },
              ]"
            >
              Details
            </div>
            <div class="pNavSpace"></div>
            <div
              class="pBtn"
              @click="navActive[3] = 'view'"
              :class="[
                { navSelected: navActive[3] === 'view' },
                { navUnderlined: navActive[3] != 'view' },
              ]"
            >
              Example
            </div>
          </div>
          <div class="pBodyH" v-if="navActive[3] === 'desc'">
            <div class="pBody1">
              <div class="pLeft">
                <div class="pTitle">Description</div>
                <div class="pContent">
                  To develop a business website for a company that works with people with disabilities.<br>
                  You can find the website here: <a href="https://fullpotential-inc.org" target="_blank">Full Potential Inc.</a>
                </div>
                <br /><br /><br />
                <div class="pTitle">Requirements</div>
                <div class="pContent">
                  <ul>
                    <li>Display info about the company</li>
                    <li>
                      Allow users to message the company
                    </li>
                    <li>
                      Display all services offered by the company
                    </li>
                    <li>Provide valuable resources</li>
                    <li>Low cost design</li>
                  </ul>
                </div>

                <!-- <p>
                  This project was to take an existing squarespace website and come up with a method of integrating more complex functionality within it. <br><br>
                  The goal was to update the website to display current products available at specific locations, and to allow the user to search for the closest location of a specific product.
                  If possible, the site was to be unchanged otherwise and it needed to be done in an effecient mannor.
                </p> -->
              </div>
              <div class="pRight">
                <div class="pTitle">Outcome</div>
                <div class="pListTitle">Low cost design</div>
                <ul>
                  <li>Hosted site using firebase: no yearly cost</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="pBodyV" v-else-if="navActive[3] === 'details'">
            <div class="pBody2">
              <div class="pBlock1">
                <div class="pTitle">Solution</div>
                <div class="pContent">
                  <!-- Details > Solution -->
                  To develop a website from scratch using vue.js to meet clients
                  exact design requests<br /><br />
                  <div class="pListTitle">Firebase</div>
                  <ul>
                    <li>Website is hosted in firebase</li>
                  </ul>
                  <br />
                  <div class="pListTitle">Message Functionality</div>
                  <ul>
                    <li>
                      Messages and contact info are emailed to the company using
                      emailjs
                    </li>
                  </ul>
                </div>
              </div>
              <div class="pBlock2">
                <div class="pTitle">Tech Stack</div>
                <div class="pContent">
                  <ul>
                    <li>Firebase (Firebase hosting)</li>
                    <li>Vue.js</li>
                    <li>Javascript, HTML, CSS</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="pBodyI" v-else>
            <iframe
              src="https://fullpotential-inc.org"
              width="100%"
              height="100%"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </project-view>
    </div>

    <div ref="contact" id="contact">
      <contact-view></contact-view>
    </div>
  </div>
  <!-- </div>
  <div v-else>
    <div class="mBackground">
      <div class="mTop">
        <div class="mLogoImg">
          <img src="../assets/logo2.png" class="mLogoImg1" />
        </div>
      </div>
      <div class="mBottom">
      </div>
    </div>
  </div> -->
  <div id="pageBottom"></div>
</template>
<script>
import TheHeader from '../components/layouts/TheHeader.vue'
import HomeView from '../components/layouts/HomeView.vue'
import AboutView from '../components/layouts/AboutView.vue'
import ContactView from '../components/layouts/ContactView.vue'
import ProjectsView from '../components/layouts/ProjectsView.vue'
import ProjectView from '../components/layouts/ProjectView.vue'
// import VideoPlayer from "../components/layouts/VideoJS.vue";
export default {
  components: { TheHeader, HomeView, AboutView, ContactView, ProjectsView, ProjectView },
  name: 'mainview',
  data () {
    return {
      scrollPosition: null,
      showHeader: false,
      activePage: 'home',
      // p1Active: 'desc',
      navActive: [],
      isMobile: false,
      videoOptions: {
        autoplay: true,
        controls: false,
        preload: true,
        fluid: true,
        playsinline: true,
        loop: true,
        muted: true,
        sources: [
          {
            src: require('../assets/Dabstract.mp4'),
            type: 'video/mp4'
          },
          {
            src: require('../assets/Dabstract.webm'),
            type: 'video/webm'
          }
        ]
      }
    }
  },
  computed: {
    showReturnToTopButton () {
      return this.scrollPosition > 100
    },
    returnButtonClass () {
      if (this.scrollPosition > window.innerHeight + 30) {
        return 'darkView'
      } else {
        return 'lightView'
      }
    }

  },
  methods: {
    getDivPos (name) {
      return this.$refs[name].getBoundingClientRect().top
    },
    updateScroll () {
      // console.log('this.getDivPos: ' + this.getDivPos('contact'))
      const curPos = window.scrollY
      if (curPos < this.scrollPosition) {
        this.showHeader = true
      } else if (curPos > this.scrollPosition) {
        this.showHeader = false
      }
      this.scrollPosition = window.scrollY

      if (this.getDivPos('contact') < 60) {
        this.activePage = 'contact'
        if (
          Math.abs(
            document.documentElement.clientHeight - this.getDivPos('contact')
          ) < 0.05
        ) {
          this.showHeader = false
        }
      } else if (this.getDivPos('projects') < 60) {
        this.activePage = 'projects'
        if (
          Math.abs(
            document.documentElement.clientHeight - this.getDivPos('projects')
          ) < 0.05
        ) {
          this.showHeader = false
        }
      } else if (this.getDivPos('about') < 60) {
        this.activePage = 'about'
        if (
          Math.abs(
            document.documentElement.clientHeight - this.getDivPos('about')
          ) < 0.05
        ) {
          this.showHeader = false
        }
      } else {
        this.activePage = 'home'
        if (
          Math.abs(
            document.documentElement.clientHeight - this.getDivPos('home')
          ) < 0.05
        ) {
          this.showHeader = false
        }
      }
      document.title = this.activePage.charAt(0).toUpperCase() + this.activePage.slice(1) + ' | Rexroth Development'

      if (this.$route.query.id != this.activePage) {
        this.$router.push({ query: { id: this.activePage }, params: { update: 'false' } })
      }
      // var url = window.document.URL
      // window.document.URL = url.slice(0,url.indexOf("id=")) + "id=" + this.activePage;
      // console.log(window)
      // console.log(document)
      // // if(this.getDivPos('home'))
      // if (
      //   // (window.innerHeight || document.documentElement.clientHeight) >
      //   // this.getDivPos("contact")
      //   document.documentElement.scrollTop < this.getDivPos('about')
      // )
      // {
      //   this.activePage = "home";
      //   if (
      //     Math.abs(
      //       document.documentElement.clientHeight - this.getDivPos("home")
      //     ) < 0.05
      //   ) {
      //     this.showHeader = false;
      //   }
      // }
      // else if (
      //   // (window.innerHeight || document.documentElement.clientHeight) >
      //   (document.documentElement.scrollTop) <
      //   this.getDivPos("projects")
      // ) {
      //   this.activePage = "about";
      //   if (
      //     Math.abs(
      //       document.documentElement.clientHeight - this.getDivPos("about")
      //     ) < 0.05
      //   ) {
      //     this.showHeader = false;
      //   }
      // }
      // else if (
      //   // (window.innerHeight || document.documentElement.clientHeight) >
      //   (document.documentElement.scrollTop) <
      //   this.getDivPos("contact")
      // ) {
      //   this.activePage = "projects";
      //   if (
      //     Math.abs(
      //       document.documentElement.clientHeight - this.getDivPos("projects")
      //     ) < 0.05
      //   ) {
      //     this.showHeader = false;
      //   }
      // }
      // else {
      //   this.activePage = "contact";
      //   if (
      //     Math.abs(
      //       document.documentElement.clientHeight - this.getDivPos("contact")
      //     ) < 0.05
      //   ) {
      //     this.showHeader = false;
      //   }
      // }
      // console.log(this.scrollPosition);
    },
    windowSize () {
      // console.log(window.innerWidth);
      if (window.innerWidth <= 900) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
      console.log('isMobile: ' + this.isMobile)
    },
    goto (refName) {
      const element = this.$refs[refName]
      let top = element.offsetTop
      if (refName === 'home') {
        top = 0
      }
      window.scrollTo({
        left: 0,
        top: top,
        behavior: 'smooth'
      })
    }
    // scrollTo(){
    //   if(this.$router.currentRoute.hash){
    //     var refName = this.$router.currentRoute.hash;
    //     var element = this.$refs[refName];
    //     var top = element.offsetTop;
    //     if (refName === "home") {
    //       top = top - 8;
    //     }
    //     window.scrollTo({
    //       left: 0,
    //       top: top,
    //       behavior: "smooth",
    //     });
    //   }

    // }
  },
  mounted () {
    window.addEventListener('scroll', this.updateScroll)
    window.addEventListener('resize', this.windowSize)
    this.windowSize()
    // this.$router.push({ name: "home", hash: '', params: { top: top}})
  },
  created () {
    for (let i = 0; i < 4; i++) {
      this.navActive.push('desc')
    }
  },
  unmounted () {
    window.removeEventListener('scroll', this.updateScroll)
  }
}
</script>

<style scoped>
/* Project View */
ul {
  margin: 0;
}
li {
  margin-bottom: 8px;
}
video {
  width:100%;
  height: 100%;
  max-height:100%;
  position:relative;
}
.pListTitle {
  font-weight: 600;
}
.pView {
  width: 100%;
  height: calc(100% - 75px);
  /* width:96%;
  height:96%;
  margin-top:2%;
  margin-left:2%; */
  flex-grow: 1;
  background-color: rgb(127, 130, 135);
  position:relative;
}
.pNav {
  width: 100%;
  background-color: rgb(86, 105, 133);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 40px;
}
.pNavSpace {
  border: 1px solid rgb(53, 63, 79);
  height: 100%;
  box-sizing: border-box;
}
.pBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.pBtn:hover {
  background-color: rgb(127, 130, 135);
  cursor: pointer;
}
.navSelected {
  background-color: rgb(127, 130, 135);
}
.navUnderlined {
  border-bottom: 1px solid rgb(53, 63, 79);
}
.pBodyH {
  /* background-color:green; */
  width: 100%;
  height: calc(100% - 40px);
  /* display: flex;
  justify-content: space-evenly;
  align-items: center; */
}
.pBody1 { 
  display: flex;
  justify-content: space-evenly;
  align-items: center; 
  /* margin: 5%; */
  height:100%;
}
.pBody2 { 
  width:80%;
  /* margin-left:10%; */
  /* margin: 5%; */
  /* display: flex;
  justify-content: space-evenly;
  align-items: center; 
  margin: 5%; 
  height:100%; */
}
.pBodyV {
  width: 100%;
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position:relative;
}
.pBodyI {
  width: 100%;
  height: calc(100% - 40px) !important;

  display: flex;
  justify-content: center;
  align-items: center;
  position:relative;
}

.pBodyI iframe {
  width: 90%;
  height: 90%;
  border: 5px solid white;
  box-sizing: border-box;
}
/* .vjs_tech {
  width:90%;
  height:90%;
  border: 5px solid white;
  box-sizing:border-box;
} */
/* #vjs_video_3, .vjs_tech {
  width: 90%;
  height:90%;
  border: 5px solid white;
  box-sizing:border-box;
} */
.pLeft,
.pRight {
  width: 40%;
  height: 80%;
  padding: 20px;
  background-color: white;
  overflow-y:auto;
}
.pLeft p {
  padding: 20px;
}

.pBlock1,
.pBlock2 {
  background-color: white;
  width: calc(100% - 40px);
  padding: 20px;
}
.pBlock1 {
  max-height: 40vh;
  margin-bottom:5%;
  overflow-y: auto;
}
.pBlock2 {
  max-height: 15vh;
  overflow-y: auto;
}
.pTitle {
  font-size: 2em;
  border-bottom: 1px solid black;
  margin-bottom: 20px;
}
.theHeader {
  position: fixed;
  top: 0;
  width: calc(100% - 16px);
  border-top: 8px solid white;
  box-sizing: border-box;
  z-index: 100;
}
/* Back To Top Button */
.returnButton {
  position: fixed;
  bottom: 2vh;
  right: 3vh;
  text-decoration: underline;
}
.returnButton:hover {
  color: rgb(139, 139, 139);
  cursor: pointer;
}
.darkView {
  color: rgb(226, 226, 226);
}
.darkView:hover {
  color: rgb(194, 194, 194);
}
.lightView {
  color: rgb(83, 83, 83);
}
.lightView:hover {
  color: rgb(179, 179, 179);
}

.video1 {
  width: 90%;
  height: 90% !important;
  /* height:calc(90% - 75px); */
  border: 5px solid white;
  box-sizing: border-box;
  text-align:center;
  background-color:black;
}
.v-enter-from {
  opacity: 0;
}
.v-enter-active {
  transition: all 0.5s ease-out;
}
.v-enter-to {
  opacity: 1;
}
.v-leave-from {
  opacity: 1;
}
.v-leave-active {
  transition: all 0.5s ease-in;
}
.v-leave-to {
  opacity: 0;
}
@media only screen and (max-width: 900px) {
  .pBodyH {
    border: 0px solid;
    box-sizing:border-box;
    padding:5%;
  }
  .pBody1 {  
    display: block;
    overflow-y:auto;
  }
  .pBodyV {
    display:block;
    border: 0px solid;
    box-sizing: border-box;
    padding:5%;
  }
  .pBody2 {
    width:100%;
    height:100%;
    overflow-y: auto;
  }
  .pRight, .pLeft {
    position:relative;
    overflow-y:hidden;
    width:inherit;
    height:fit-content;
  }
  
  .pRight {
    margin-top:5%;
  }
  .pBlock1,.pBlock2{
    /* padding:0; */
    height: fit-content;  
    overflow-y:hidden;
    max-height:none;
  }
  .pBodyI {
    height: 60vh;
  }
  .pView {
    height: calc(100% - 130px);
    position:relative;
    /* height:auto; */
  }
}
</style>
