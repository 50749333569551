<template>
  <div class="d1">
    <h2>Login</h2>
    <form @submit.prevent="login">
      <input type="text" v-model="email" placeholder="Username" />
      <input type="password" v-model="password" placeholder="Password" />
      <button type="submit">Login</button>
    </form>
  </div>
</template>

<script>
import { signInWithEmailAndPassword, getAuth } from 'firebase/auth';
export default {
  data() {
    return {
      email: '',
      password: '',
      errorMessage: ''
    };
  },
  methods: {
    async login() {
      this.errorMessage = '';
      if(this.email === ''){
        this.errorMessage = "You must enter a valid email address";
        return;
      } else if(this.password===''){
        this.errorMessage="You must enter a valid password";
        return;
      }
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then((userCred) => {
          const user = userCred.user;
          localStorage.setItem('authToken', userCred.user.getIdToken(true));
          localStorage.setItem('authRefreshToken', userCred.user.refreshToken);
          console.log(user + "created successfully");
        })
        .catch((error) => {
          this.errorMessage = error.message.replace('Firebase: ', '');
        });
      
      // Store the token in localStorage or Vuex store
      

      // Redirect to the preview page
      this.$router.push('/preview');
    }
  }
};
</script>

<style scoped>
.d1 {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items:center;
  margin-top:20%;
}
.p1 {
  color: red;
}
</style>