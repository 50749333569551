<template>
    <div class="about">
      <div class="aboutLabel">
        <div class="aboutLabel1">
          About
        </div>
      </div>
      <div class="aboutDesc">
        <div class="aboutDesc1">
          <div class="aboutDesc2">
          <h1>Rexroth Development LLC</h1> provides <h1>software development services</h1> to individuals or companies in need of <h1>custom software solutions</h1>.
          Currently, it is owned and operated by myself, Andrew Rexroth. I am a Computer Engineer who has been working as a <h2>full-stack developer</h2> since 2016. I studied
          <h2>Computer Engineering</h2>, <h2>Software Engineering</h2>, and Math at Trine University until 2015. I worked as a Software Developer and <h1>Lead Software Developer</h1> with Tempus Technologies for 5 years before transitioning to <h1>contract freelance software development</h1>.<br>
          <img class="img1" src="../../assets/profilePic2.jpg" alt="Andrew Rexroth - Senior Developer"/>
          <br>
          I have a wide variety of experience with both <h1>front-end and back-end development</h1> including: <h2>IIS Services</h2>, <h2>Microservices</h2>, <h2>Websocket Services</h2>, <h2>IVR Systems</h2>, <h2>Amazon Web Services</h2>, <h2>Twilio Cloud Based Messaging Services</h2>, <h2>Website Development</h2> (custom and template) etc… I have worked with many tech stacks including: <h2>C# .NET, Delphi, Vue.js, React, Javascript, Python, Html, CSS, Java, Node, SQL, MySQL, AWS, Swift, Xamarin</h2> (mono) and many others. <br><br>I enjoy working closely with clients to design a unique solution that meets their requirements in an efficient and cost effective manner. From small to large scale projects, <h2>personal or business websites</h2>, <h2>API integrations</h2>, <h2>backend local or cloud based services</h2>, we will design a solution that best fits your needs.
          </div>
        </div>
      </div>
    </div>
</template>

<script>

export default {

}
</script>

<style scoped>
.about {
  background-color:white;
  width: 100%;
  min-height: 100vh;
  height:fit-content;
  display:flex;
}
/* .aboutLabel {
  color:rgb(83, 83, 83);
  float:left;
  height: 35vh;
  margin-top:25vh;
  padding-top:15vh;
  border-right:2px solid rgb(83, 83, 83);
  font-size:4rem;
  width:49vw;
  text-align:center;
} */
.aboutLabel {
  color: rgb(83,83,83);
  min-height:100vh;
  width:50vw;
  font-size:4rem;
  display:flex;
  justify-content: center;
  align-items:center;
}
.aboutLabel1 {

  display:flex;
  justify-content: center;
  align-items:center;
  height:60%;
  width:100%;
  box-sizing:border-box;
  border-right:2px solid rgb(83,83,83);
}
.aboutDesc {
  display:flex;
  justify-content: center;
  align-items:center;
  /* float: right; */
  width:50vw;
  min-height:100vh;
}
h1,h2 {
  font-size:1.2rem;
  font-weight:normal;
  /* white-space:nowrap; */
  margin:0;
  display: inline;
}
.aboutDesc1{
  font-size: 1.2em;
  width:80%;
  /* height:80%; */
  display:flex;
  justify-content: center;
  align-items:center;
  margin-top: 50px;
  margin-bottom:50px;
}
.aboutDesc2{
  max-width:100%;
  max-height:100%;
  /* overflow-y:auto; */
}
.descScroll {
  height:100%;
  width:100%;
  /* overflow-y:auto; */
}

.img1 {
  width:30%;
  height:auto;
  margin-top:10px;
  margin-bottom:10px;
  margin-left:35%;
  text-align:center;

}
@media only screen and (max-width: 900px) {
  .about{
    flex-direction:column;
    align-items:center;
    width:100%;
  }
  .aboutLabel{
    min-height:auto;
  }
  .aboutLabel1{
    border:none;
    border-bottom:2px solid rgb(83,83,83);
    padding:10px;    
  }
  .aboutDesc {
    width:85%;
    display:block;
  }
  .aboutDesc1{
    width:100%;
    height:75%;
  }
  .img1 {
    width:50%;
    margin-left:25%;
  }
}
</style>
